
.wrapper{
    display: flex;
    justify-content: center;
    background-color: #f2f2f2;
}

.labelInputWrapper{
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    margin: 5px;
    margin-right: 10px;
    margin-left: 10px;
    width: 100%;
}
.form{
    position: relative;
    padding: 24px;
    padding-top: 7%;
    margin-top: 30px;
    background-color: aliceblue;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    height: 80%;
    border-radius: 16px;
    }
.textInput{
    border: 1px darkgray solid;
    border-radius: 3px;
    margin: 5px;
    position: relative;
    right: 0;
    padding: 5px 10px;
    font-size: 1.2rem;
}
.textInput:focus{
    box-shadow: 0px 0px 1px 1px rgb(0, 157, 255);
    outline: none;
}
.label{
    text-align: right;
    padding-right: 10px;
    color: black;
    font-size: 1.2rem;
}
.submitClearTermsWrapper{
    display: flex;
    flex-direction: column;
    width: 100%;
    align-content: center;
    align-items: center;
}
#terms{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color:#f2f2f2 ;
    border-radius: 5px;
    font-size: 1.2rem;
    width: 100%;
    border: 1px darkgray solid;
}
.checkboxLabel{
  padding-left: 10px;
  cursor: pointer;
  padding-top: 2px;
}

footer{
    display: flex;
}
#clear{
    background-color: hsl(333deg, 100%, 44%);
    font-size: 1.2rem;
    z-index: 999;
}
#submit{
    background-color: hsl(256deg, 100%, 44%);
    font-size:1.2rem;
    cursor: pointer;
    z-index: 999;
}
#clear:hover{
    cursor: pointer;
}
#clear{
    color:#fff ;
    font-weight: 900;
    border-radius: 5px;
    padding: 5px;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 1.2rem;
}
#submit:hover{
    cursor: pointer;
}
#submit{
    color:#fff ;
    font-weight: 900;
    border-radius: 5px;
    padding: 5px;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 1.2rem;
}
#form-title{
    font-size: clamp(1rem, 1.6rem, 2rem);
    font-weight: 900;
    position: absolute;
    color: black;
    top:3%;
    text-decoration: underline;
}


#checkboxInput{
    position: relative;
    width: 20px;
    height: 20px;
    border-radius: 3px;
    border: 1px darkgray solid;
    background-color: #fff;
    cursor: pointer;
}

@media (max-width: 1000px) {
    .form{
        height:100%;
        top:0;
        display: flex;
        flex-direction: column;
    }
    .label{
        display: none;
    }
    .submitClearTermsWrapper{
        width: 100%;
    }
    #form-title{
        top:-3%;
        text-decoration: none;
        font-size: 20px;
    }
   
}