
  .chat-app {
    background: #fff;
    border: #eaeaea;
    border-radius: 4px;
    box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.15);
    height: 100vh;
    width: 100%;
    max-width: 360px;
  }
  .chat-app__header {
    background: #4287f5;
    border-radius: 4px 4px 0
      0;
    box-shadow: 0px 3px 3px -1px rgba(0, 0, 0, 0.06);
    height: 70px;
    display: flex;
    align-items: center;
    padding: 0 24px;
  }
  .chat-app__header h2 {
    color: #fff;
  }
  
  .chat-app__content {
    display: flex;
    flex-direction: column-reverse;
    height: calc(100% - 70px);
  }
  .user-form {
    box-shadow: 0px -3px 3px -1px rgba(0, 0, 0, 0.06);
    display: flex;
    z-index: 1;
  }
  .user-form--label {
    display: none;
  }
  .user-form--input {
    flex: 1 0 auto;
    border: none;
    border-radius: 0 0 0 4px;
    height: 40px;
    padding: 4px 8px;
  }
  .user-form--button {
    background: #4287f5;
    color: #fff;
    cursor: pointer;
    border: none;
    border-radius: 0 0 4px 0;
    letter-spacing: 0.95px;
    padding: 3px 8px;
    font-weight: 700;
  }
  .conversation {
    margin-top: auto;
    background: #fff;
    overflow: hidden;
  }
  .conversation-container {
    display: flex;
    flex-direction: column;
    padding: 14px 14px 28px;
    overflow-y: scroll;
    width: calc(100% + 18px);
    scroll-behavior: smooth;
  }
  .message {
    margin-top: 6px;
    display: flex;
  }
  .message span {
    background: whitesmoke;
    border-radius: 3px;
    display: inline-block;
    padding: 6px 12px;
    margin-right: 30px;

  }
  .message.user span {
    background: #189be6;
    color: #fff;
    margin-left: auto;
    margin-right: 30px;
  }
  
  @media screen and (min-width: 360px) {
    .chat-app {
      height: 600px;
    }
    .chat-app_content {
      border: 1px solid green;
      height: calc(600px - (70px + 40px));
    }
    .conversation-container {
      max-height: calc(600px - (70px + 40px));
    }
  }
  