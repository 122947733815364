/* Hide scrollbar for Firefox */
html {
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;
  }
  
  /* Hide scrollbar for WebKit browsers */
  body::-webkit-scrollbar {
    width: 0px;
  }
  
  body::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
  
  body::-webkit-scrollbar-track {
    background: transparent;
  }
  
  /* Additional styles to make it cross-browser compatible */
  body {
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;
  }